import React from "react";

type Props = {};

export default function Body(props: Props) {
    return (
        <div>
            <div className="relative py-16 sm:py-24" style={{background: `url(/background.svg)`}}>
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="relative rounded-2xl px-6 py-10 overflow-hidden sm:px-12 sm:py-20">
                        <div className="relative">
                            <div className="sm:text-center">
                                <h1 className="text-[4rem] text-white tracking-tight">
                                    Laser Ticketing Systems
                                </h1>
                                <p className="mt-6 mx-auto max-w-3xl text-lg text-gray-300">
                                    Laser Ticketing Systems is established as the only UK company totally dedicated to help retailers produce smart product ticketing for in-store displays.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-2xl my-16 mx-auto sm:my-24">
                <h2 className="font-normal text-[2.3rem] text-slate-900 text-center">New Website Coming Soon....</h2>
            </div>
        </div>
    );
}
