import React from "react";

type Props = {};

export default function Footer(props: Props) {
    return (
        <div className="border-slate-500">
            <div className="max-w-3xl mx-auto border-t border-slate-150 text-center">
                <p>Please contact us on: <a href="tel:01424 22 55 22">01424 22 55 22</a> or</p>
                <p><a href="mailto:support@laserticketing.co.uk" className="underline">support@laserticketing.co.uk</a></p>
                <p className="mt-20 mb-2">Copyright {new Date().getFullYear()} - Laser Ticketing Systems Ltd</p>
            </div>
        </div>
    );
}
