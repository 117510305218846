import React from 'react'
import Header from './header'
import Body from './body'
import Footer from './footer'

type Props = {

}

export default function App(props: Props) {
    return (
        <main>
            <Header />
            <Body />
            <Footer />
        </main>
    )
}
